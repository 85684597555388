<template>
  <!-- <div class="container">
    <div class="title">THUSSAT考务管理系统</div>
    <el-form
      :model="loginForm"
      :rules="loginFormRules"
      ref="loginFormRef"
    >
      <el-form-item prop="name">
        <el-input v-model="loginForm.name" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input v-model="loginForm.password" placeholder="密码" show-password></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="submitForm()">登录</el-button>
      </el-form-item>
    </el-form>
  </div> -->
  <div style="border:none">
    <div
      class="left-login"
      style="
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        width: 600px;
        padding: 40px;
        background: url('https://studentinfo.oss-cn-qingdao.aliyuncs.com/mo/bgimg.png')
          no-repeat;
        background-size: cover;
      "
    >
      <h2
        style="
          font-size: 30px;
          color: #fff;
          font-weight: normal;
          font-family: 'Times New Roman';
        "
      >
        Mirror
      </h2>
      <!-- <p style="font-size: 12px;color: #fff;">标准学术能力测试</p> -->
      <div
        style="
          position: absolute;
          left: 40px;
          right: 40px;
          top: 50%;
          transform: translateY(-50%);
        "
      >
        <p style="font-size: 28px; color: #fff">考务管理系统</p>
        <p
          style="
            font-size: 16px;
            color: rgba(255, 255, 255, 0.8);
            margin-top: 30px;
          "
        >
          本系统为组织考试的考务人员提供后台管理服务
        </p>
      </div>
      <!-- <p style="color: rgba(255,255,255,.5);font-size: 14px;position: absolute;left: 40px;bottom: 40px;">标准学生能力测试项目组</p> -->
    </div>
    <div
      class="container"
      style="width: 600px;position: fixed; top: 30%;left:600px;right:0; z-index: 1;display:flex;justify-content:center;"
    >
      <div id="login_form">
        <h3
          style="
            color: #333333;
            font-size: 28px;
            text-align: center;
            margin-bottom: 30px;
          "
        >
          系统登录
        </h3>
      </div>
      <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef">
        <el-form-item
          prop="name"
          class="f-f1 item-ifo"
          style="position: relative"
        >
          <el-input
            class="name"
            v-model="loginForm.name"
            placeholder="请输入手机号"
            maxlength="11"
            style="width:450px;padding:0;"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          class="f-f1 item-ifo"
          style="position: relative;padding:0"
        >
          <el-input
            class="password"
            v-model="loginForm.password"
            type="password"
            placeholder="密码"
            maxlength="20"
            id="realPwd"
            style="width:450px"
            show-password
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="login" style="width:450px" @click="submitForm()"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { authMixin } from "mixins/admin/auth";
import { login } from "r/admin/login"

export default {
  name: "Login",
  mixins: [authMixin],
  data() {
    return {
      loginForm: {
        name: "",
        password: "",
      },
      // 表单验证规则对象
      loginFormRules: {
        // 对用户名进行校验
        name: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: "请输入正确的手机号", trigger: "blur" },
        ],
        // 对密码进行校验
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 15, message: "请输入正确的密码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 表单验证
    submitForm() {
      // console.log(111)
        this.$refs.loginFormRef.validate(async valid => {
        // 验证用户名和密码是否符合规定
        if (!valid) return

        // 发送请求
        const data = await login(this.loginForm)
        // console.log(data)

        // 根据返回的状态码做想关的判断
        if (data.data.code !== 200) return this.$message.error(data.data.message)

        this.$message.success(data.data.message)

        // let userInfo=JSON.parse(data.config.data)
        // console.log(userInfo)

        // token存储
        window.localStorage.setItem('admin-token', data.data.data.tokenHead+" "+data.data.data.token)
        // 存储登录用户
        window.localStorage.setItem('admin-userInfo', data.config.data)

        // 存储用户名
        window.localStorage.setItem("index-userInfo", this.loginForm.name);
        window.localStorage.setItem("admin-userInfo-name", this.loginForm.name);

        // 重置表单
        this.$refs.loginFormRef.resetFields()

        // 使用编程式导航实现跳转
        // this.$router.push({name:'admin-index', query: { userInfo: JSON.stringify(userInfo)}})
        this.$router.push({name:'admin-index'})
      })
    },
  }
};
</script>

<style scoped lang="scss">
@import "a/css/common";

.name ::v-deep .el-input__inner {
    background: none;
    border: none;
    padding: 0;
}
.password ::v-deep .el-input__inner {
  background:none ;
  border:none;
  padding: 0;
}
::v-deep .login-index[data-v-efc3b290] {
   border:none !important;

}

// .container {
//   width: 400px;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);

//   .title {
//     height: 70px;
//     font-size: 18px;
//     text-align: center;
//     font-weight: bold;
//   }

//   ::v-deep .el-form {
//     .el-input__inner {
//       width: 100%;
//       height: 45px;
//       border-radius: 0;
//     }

//     .el-button {
//       width: 100%;
//       height: 45px;
//       margin-top: 20px;
//       color: #ffffff;
//       background-color: #aaaaaa;
//     }
//   }
// }
</style>
