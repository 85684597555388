import { request } from "./network";

// 重置密码
export function reset(data) {
  return request({
    method: "post",
    url: "/",
    data,
  });
}

// 登录
export function login(data) {
  return request({
    method: "post",
    url: "/login/admin/login",
    data,
  });
}

// 退出登录
export function logout(params) {
  return request({
    method: "post",
    url: "/login/teacher/logout",
    params,
  });
}

// 获取当前登录用户信息
export function loginUserInfo(name, data) {
  return request({
    method: "get",
    url: "/login/teacher/info"+name,
    data,
  });
}
