//后台权限相关
export const authMixin = {
  data: function() {
    return {

    };
  },
  created() {
  },
  methods: {

  },
  activated() {

  },
};
