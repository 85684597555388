<template>
    <div>
        <!--<div
          class="left-login"
          style="
            position: fixed;
            left: 0;
            top: 0;
            bottom: 0;
            width: 600px;
            padding: 40px;
            background: url('https://studentinfo.oss-cn-qingdao.aliyuncs.com/mo/bgimg.png')
              no-repeat;
            background-size: cover;
          "
        >
          <h2
            style="
              font-size: 30px;
              color: #fff;
              font-weight: normal;
              font-family: 'Times New Roman';
            "
          >
            Mirror
          </h2>
          <div
            style="
              position: absolute;
              left: 40px;
              right: 40px;
              top: 50%;
              transform: translateY(-50%);
            "
          >
            <p style="font-size: 28px; color: #fff">在线监考系统</p>
            <p
              style="
                font-size: 16px;
                color: rgba(255, 255, 255, 0.8);
                margin-top: 30px;
              "
            >
              本系统为参与考试的监考人员提供在线监考服务。
              监考老师可以监控多个参试人员的监控
              针对每个参试人员，能够看到正面监控和背面监控两路监控视频
              支持广播语音通知，可对多人进行统一的消息告知
              支持点对点语音通话，发现疑似作弊行为或者软件异常场景，可快速沟通处理
            </p>
          </div>
        </div>-->
        <div
                class="container"
                style="width: 600px; position: fixed; top: 30%;left:600px;right:0; z-index: 1;display:flex;justify-content:center;"
        >
            <div id="login_form">
                <h3 style="
            color: #333333;
            font-size: 28px;
            text-align: center;
            margin-bottom: 30px;
          "
                >
                    Mirror事件处理系统
                </h3>
            </div>
            <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef">
                <el-form-item
                        prop="name"
                        class="f-f1 item-ifo"
                >
                    <el-input
                            class="name"
                            v-model="loginForm.name"
                            placeholder="请输入用户名"
                            maxlength="20"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        prop="password"
                        class="f-f1 item-ifo"
                >
                    <el-input
                            class="password"
                            v-model="loginForm.password"
                            type="password"
                            placeholder="密码"
                            maxlength="20"
                            id="realPwd"
                            show-password
                    >
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" class="login" style="width:450px" @click="submitForm()"
                    >登录
                    </el-button
                    >
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {login} from "r/jiDong/login.js";
    import routeList from "router/jiDong/index";

    export default {
        name: "Login",
        // mixins: [authMixin],
        data() {
            return {
                loginForm: {
                    name: "",
                    password: "",
                },
                // 表单验证规则对象
                loginFormRules: {
                    // 对用户名进行校验
                    name: [
                        {required: true, message: "请输入用户名", trigger: "blur"},
                    ],
                    // 对密码进行校验
                    password: [
                        {required: true, message: "请输入密码", trigger: "blur"},
                        {min: 6, max: 15, message: "请输入正确的密码", trigger: "blur"},
                    ],
                },
            };
        },
        methods: {
            // 表单验证
            submitForm() {
                this.$refs.loginFormRef.validate(async (valid) => {
                    // 验证用户名和密码是否符合规定
                    if (!valid) return;
                    // console.log(this.loginForm, "上传的用户名");

                    // 发送请求的操作
                    let {data} = await login(this.loginForm);

                    // 根据返回的状态码做想关的判断
                    if (data.code !== 200) return this.$message.error(data.message);

                    this.$message.success("登录成功！");
                    data = data.data;
                    // console.log(data, "登录返回数据");
                    // token存储
                    window.localStorage.setItem("jiDong-token", data.token);

                    // 存储用户名
                    window.localStorage.setItem("jiDong-userInfo", this.loginForm.name);

                    // 存储当前角色权限
                    window.localStorage.setItem('jiDong-roleId', data.roleId);

                    // 存储teacherId
                    window.localStorage.setItem("jiDong-teacherId", data.teacherId);

                    // 重置表单
                    this.$refs.loginFormRef.resetFields();

                    let routes = [];
                    routes = routeList.filter(item => {
                        if (item.meta && item.meta.roleId.includes(Number(data.roleId))) {
                            return item;
                        }
                    })
                    window.sessionStorage.setItem('jiDong-activePath', '/jiDong/' + routes[0]['path']);
                    await this.$router.push('/jiDong/' + routes[0]['path']);
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "a/css/common";

    .container {
        position: absolute;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
    }

    #login_form {
        top: -20px;
    }

    ::v-deep .el-input {
        &.name, &.password {
            width: 450px;
            padding: 0 !important;
            margin: 0 !important;
            display: flex;
            flex-flow: row;
            align-items: center;

            .el-input__inner {
                background: #e6e6e6;
                flex: 1;
                height: 100%;
                border: none;
                padding: 0;
                padding: 0 15px;
            }
        }
    }
</style>
